import gdsj from "../Images/GCSJ_logo.webp";
import FL from "../Images/figmaLifestyle_Logo(BG_white).jpeg";
import SC_24 from "../Images/SolutionChallenge_2024.webp";
import Pandas from "../Images/Pandas_9D3I4Kp.webp";
import CrackWithGDSC from "../Images/CrackWGDSC.jpeg";
import techotsav from "../Images/event/TechotsavBanner.png";

const events = [
  {
    img: gdsj,
    title: "Google Cloud Study Jam",
    description: null,
    startDate: new Date("2023-08-31"),
    endDate: new Date("2023-08-31T18:00:00"),
    location: "G.H. Raisoni College of Engineering, Nagpur",
    learnMoreLink: null,
  },
  {
    img: FL,
    title: "Figma Lifestyle",
    description: null,
    startDate: new Date("2023-10-21"),
    endDate: new Date("2023-10-21T18:00:00"),
    location: "G.H. Raisoni College of Engineering, Nagpur",
    learnMoreLink: "/events/figma-lifestyle",
  },
  {
    img: SC_24,
    title: "Solution Challenge 2024",
    description: null,
    startDate: new Date("2023-12-01"),
    endDate: new Date("2024-01-20T18:00:00"),
    location: "G.H. Raisoni College of Engineering, Nagpur",
    learnMoreLink: "/events/solution-challenge",
    registerLink: null,
  },
  {
    img: Pandas,
    title: "Intoduction to Web3 and Blockchain",
    description: null,
    startDate: new Date("2024-01-29"),
    endDate: new Date("2024-01-29T12:30:00"),
    location: "G.H. Raisoni College of Engineering, Nagpur",
    learnMoreLink:
      "https://gdsc.community.dev/events/details/developer-student-clubs-gh-raisoni-college-of-engineering-nagpur-presents-intoduction-to-web3-and-blockchain/",
    registerLink: null,
  },
  {
    img: CrackWithGDSC,
    title: "Crack with GDSC 4.0",
    description: null,
    startDate: new Date("2024-02-21"),
    endDate: new Date("2024-03-16T24:00:00"),
    location: "Virtual",
    learnMoreLink:
      "https://gdsc.community.dev/events/details/developer-student-clubs-gh-raisoni-college-of-engineering-nagpur-presents-crack-with-gdsc-40/",
    registerLink: null,
  },
  {
    img: gdsj,
    title: "Flutter and Fire",
    description: null,
    startDate: new Date("2024-02-25"),
    endDate: new Date("2024-02-25T20:00:00"),
    location: "Fireblaze building, Nagpur",
    learnMoreLink:
      "https://gdsc.community.dev/events/details/developer-student-clubs-gh-raisoni-college-of-engineering-nagpur-presents-flutter-and-firebase/",
    registerLink: null,
  },
  {
    img: techotsav,
    title: "Techotsav",
    description:
      "Techotsav is an amazing opportunity to explore the constantly changing world of technology that you don't want to miss. It doesn't matter if you're an experienced programmer, a new enthusiast, or just excited by the endless possibilities of tech, this event is designed especially for you.",
    startDate: new Date("2024-04-21"),
    endDate: new Date("2024-04-21T21:00:00"),
    location: "G H Raisoni College of Engineering, Nagpur",
    learnMoreLink: "https://techotsav.gdscghrce.in/",
    registerLink: "https://docs.google.com/forms/d/e/1FAIpQLSct_Mi-H_BLlEBitqy65KJFimFZ7RBGZj0r-Hy8mgqHc-G_Rg/viewform",
  },
  {
    img: gdsj,
    title: "Build With AI",
    description: "In this hands-on workshop, you'll dive into the world of computer vision with OpenCV and leverage the power of Azure cloud services to build real-world AI projects. No prior experience necessary!",
    startDate: new Date("2024-05-24"),
    endDate: new Date("2024-05-24T18:00:00"),
    location: "Fireblaze building, Nagpur",
    learnMoreLink: "https://gdsc.community.dev/events/details/developer-student-clubs-g-h-raisoni-college-of-engineering-nagpur-india-presents-genai-study-jams/",
    registerLink: null,
  }
];

export default events;
