import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function LOR() {
  let { refID } = useParams();

  const [fetchedData, setFetchedData] = useState([]);
  const [fetched, setFetched] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(
        `https://script.google.com/macros/s/AKfycbzKBEyUPgzFsE0T6wW1bfqBdgTDhOaJq0m9jRCPlMsSP_GZCsO_mxPEnXC7XIZH7IA89A/exec?Reference=${refID}`
      );

      setFetched((x) => x + 1);

      setFetchedData((x) => [...x, ...data]);
    };

    getData();
  }, [refID]);

  return (
    <div className="min-h-[90dvh] flex items-center justify-center">
      {fetched ? (
        <>
          <div className="Certificate-Page flex flex-col items-center justify-center">
            <div className="grid  h-fit place-items-center text-center">
              <p>Certificate ID</p>
              <p>{fetchedData[0].ID}</p>
              <p>Name</p>
              <p>{fetchedData[0].Name}</p>
              <p>Date Of Issue</p>
              <p>
                {fetchedData[0]["DOI"]
                  .substring(0, 10)
                  .split("-")
                  .reverse()
                  .join("-")}
              </p>
            </div>
            <p className="font-xl  text-center p-2">
              TYour hard work and dedication have truly made a positive impact,
              and we are deeply thankful for your support. Thank you for being
              an essential part of our team and for helping to create a
              welcoming and enriching environment for all.
            </p>
          </div>
        </>
      ) : (
        "LOADING..."
      )}
    </div>
  );
}
