import Hero from "../../Components/figmaEvent/Hero";
import Navbar from "../../Components/figmaEvent/Navbar";
import About from "../../Components/figmaEvent/About";
import "./figmaEvent.css";
import Speakers from "../../Components/figmaEvent/Speakers";
import Schedule from "../../Components/figmaEvent/Schedule";
import Sponsors from "../../Components/figmaEvent/Sponsors";
import Organizers from "../../Components/figmaEvent/Organizers";
import Footer from "../../Components/figmaEvent/Footer";
import ShapesBG from "../../Components/figmaEvent/ShapesBG";
import Perks from "../../Components/figmaEvent/Perks";
import { useEffect, useState } from "react";
import Loader from "../../Components/figmaEvent/Loader";
import { Helmet } from "react-helmet";
import Glimpse from "../../Components/figmaEvent/Glimpse";

export default function FigmaEvent() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="figmaEvent">
          <ShapesBG />
          <Navbar />
          <Hero />
          <Sponsors />
          <About />
          <Speakers />
          <Organizers />
          <Schedule />
          <Perks />
          <Glimpse />
          <Footer />
        </div>
      )}
    </div>
  );
}
